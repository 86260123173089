// entryQuery gets replaced at runtime
export const pageQuery = `
    query page($uri: String) {
        entry(uri: [$uri]) {
            __typename
            id
            title
            uri
            slug

            [entryQuery]

            seomatic(asArray: true) {
                metaJsonLdContainer
                metaLinkContainer
                metaScriptContainer
                metaSiteVarsContainer
                metaTagContainer
                metaTitleContainer
            }
        }
    }
`