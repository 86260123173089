<template>
    <div class="flex flex-col min-h-screen">
        <!-- eslint-disable-next-line -->
        <div v-if="digistormSettings?.fontHtml" v-html="digistormSettings.fontHtml" />

        <Navigation />

        <slot />

        <div class="mt-auto">
            <Footer />
        </div>
    </div>
</template>

<script setup>
    import Navigation from '~/components/navigation/Navigation'
    import Footer from '~/components/footer/Footer'

    provide('page-type', 'page')

    const siteStore = useSiteStore()

    const { digistormSettings, globalTheme } = storeToRefs(siteStore)

    await siteStore.loadPage()

    const roundness = computed(() => {
        switch (digistormSettings.value?.roundness) {
            case 'rounded':
                return 'soft'
            case 'circular':
                return 'pill'
            case 'sharp':
                return 'sharp'
            default:
                return 'default'
        }
    })

    useHead({
        htmlAttrs: {
            'data-theme': globalTheme.value,
            'data-mode': `base radius-${roundness.value}`,
            class: 'surface-background'
        }
    })


</script>

<style lang="scss" scoped>

</style>
