<template></template>

<script setup>
    import { onMounted } from 'vue'

    const props = defineProps({
        siteKey: {
            type: String,
            required: true,
        },
    })

    const loadReCaptchaScript = (siteKey) => {
        return new Promise((resolve, reject) => {
            useHead({
                script: [
                    {
                        src: `https://www.google.com/recaptcha/api.js?render=${siteKey}`,
                        async: true,
                        defer: true,
                        onload: resolve,
                        onerror: reject,
                    },
                ],
            })
        })
    }

    const executeReCaptcha = (siteKey) => {
        return new Promise((resolve, reject) => {
            if (!window.grecaptcha) {
                reject('ReCaptcha not loaded')
                return
            }
            grecaptcha.ready(() => {
                grecaptcha.execute(siteKey, {action: 'submit'}).then(resolve).catch(reject)
            })
        })
    }

    const getToken = () => {
        return executeReCaptcha(props.siteKey)
    }

    onMounted(() => {
        loadReCaptchaScript(props.siteKey).catch(console.error)
    })

    defineExpose({getToken})
</script>
