import { ds_Events_Entry } from './ds_Events_Entry'
import { ds_Homepage_Entry } from './ds_Homepage_Entry'
import { ds_News_Entry } from './ds_News_Entry'
import { ds_RedirectPage_Entry } from './ds_RedirectPage_Entry'
import { ds_StandardPage_Entry } from './ds_StandardPage_Entry'

export default {
    ds_Homepage_Entry,
    ds_StandardPage_Entry,
    ds_RedirectPage_Entry,
    ds_Events_Entry,
    ds_News_Entry
}