export default defineNuxtPlugin(async (nuxtApp) => {
    nuxtApp.hook('apollo:error', (error) => {
        console.log('apollo:error', error)
        const errorType = error.networkError ? 'Network' : 'GraphQL'

        showError({
            statusCode: 500,
            statusMessage: `Something went wrong`,
            message: `${errorType} error`,
        })
    })
})
