
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/en'
import 'dayjs/locale/en-au'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(updateLocale)

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(utc)



// defaultLocale: "en-au"


dayjs.updateLocale("en-au")
dayjs.locale('en-au')


export default dayjs
