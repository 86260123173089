export const ds_Events_Entry = `
    fragment ds_Events_Entry on ds_Events_Entry {
        id
        canonicalUid
        title
        eventExcerpt
        category {
            id
            title
        }
        image {
            url
        }
        startDate
        endDate
        location
        language
        dateCreated
        articleContentBuilder {
            __typename
        }
    }
`