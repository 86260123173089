export const ds_StandardPage_Entry = `
    fragment ds_StandardPage_Entry on ds_StandardPage_Entry {
        canonicalUid
        typeHandle
        hideBanner
        bannerImage {
            url @transform(width: 1920, height: 1080)
            focalPoint
        }
        popup {
            canonicalUid
        }
        preFooter {
            canonicalUid
        }
        contentBuilder {
            __typename
        }
    }
`
