import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccordionItem, LazyAlertBar, LazyArticleFooter, LazyArticleHeader, LazyBreadcrumbs, LazyCalendarAthleticResults, LazyCalendar, LazyDateFormat, LazyFormReCaptcha, LazyFormSchema, LazyFormFieldsCheckbox, LazyFormFieldsDatePicker, LazyFormFieldsDropdown, LazyFormFieldsEmail, LazyFormFieldsFileUploader, LazyFormFieldsMultiLine, LazyFormFieldsNumberInput, LazyFormFieldsPhone, LazyFormFieldsRadio, LazyFormFieldsSingleLine, LazyFormFieldsTimePicker, LazyGhostArticle, LazyImage, LazyLightbox, LazyLinkItem, LazyPagination, LazyRichText, LazySearch, LazySkeleton, LazySlider, LazySystemMessage, LazySystemIconsEmptyAsync, LazySystemIconsNoResultsAsync, LazyVideoPlayer } from '#components'
const lazyGlobalComponents = [
  ["AccordionItem", LazyAccordionItem],
["AlertBar", LazyAlertBar],
["ArticleFooter", LazyArticleFooter],
["ArticleHeader", LazyArticleHeader],
["Breadcrumbs", LazyBreadcrumbs],
["CalendarAthleticResults", LazyCalendarAthleticResults],
["Calendar", LazyCalendar],
["DateFormat", LazyDateFormat],
["FormReCaptcha", LazyFormReCaptcha],
["FormSchema", LazyFormSchema],
["FormFieldsCheckbox", LazyFormFieldsCheckbox],
["FormFieldsDatePicker", LazyFormFieldsDatePicker],
["FormFieldsDropdown", LazyFormFieldsDropdown],
["FormFieldsEmail", LazyFormFieldsEmail],
["FormFieldsFileUploader", LazyFormFieldsFileUploader],
["FormFieldsMultiLine", LazyFormFieldsMultiLine],
["FormFieldsNumberInput", LazyFormFieldsNumberInput],
["FormFieldsPhone", LazyFormFieldsPhone],
["FormFieldsRadio", LazyFormFieldsRadio],
["FormFieldsSingleLine", LazyFormFieldsSingleLine],
["FormFieldsTimePicker", LazyFormFieldsTimePicker],
["GhostArticle", LazyGhostArticle],
["Image", LazyImage],
["Lightbox", LazyLightbox],
["LinkItem", LazyLinkItem],
["Pagination", LazyPagination],
["RichText", LazyRichText],
["Search", LazySearch],
["Skeleton", LazySkeleton],
["Slider", LazySlider],
["SystemMessage", LazySystemMessage],
["SystemIconsEmptyAsync", LazySystemIconsEmptyAsync],
["SystemIconsNoResultsAsync", LazySystemIconsNoResultsAsync],
["VideoPlayer", LazyVideoPlayer],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
