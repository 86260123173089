<template>
    <component :is="footerComponent" :settings="settings" :social-links="socialLinks">
        <template #logo>
            <img v-if="footerLogo.length > 0" :src="footerLogo[0].url" class="h-full" :class="logoSize">
        </template>

        <template #siteByDigistorm>
            <NuxtLink to="http://www.digistorm.com.au" class="font-medium text-brand-extra-strong hover:underline underline-offset-2 text-sm" target="_blank">
                site by Digistorm
            </NuxtLink>
        </template>

        <template #copyright>
            <span class="font-medium text-brand-extra-strong text-sm">
                © {{currentYear}} {{variables?.siteName}}
            </span>
        </template>
    </component>
</template>

<script setup>
    const { globalSets, digistormSettings, variables } = storeToRefs(useSiteStore())

    const settings = computed(() => {
        const navigationSet = find(globalSets.value, { __typename: 'ds_Navigation_GlobalSet'})

        return {
            footerType: digistormSettings.value.footerType,
            ...navigationSet
        }
    })

    const socialLinks = computed(() => {
        return find(globalSets.value, { __typename: 'ds_Socials_GlobalSet' })
    })

    const defaultConfig = find(globalSets.value, { __typename: 'ds_Logos_GlobalSet' })

    const footerComponent = defineAsyncComponent(() =>
        import(`./${settings.value.footerType}/footer.async.vue`)
    )

    const footerLogo = computed(() => {
        return defaultConfig?.logos_FooterLogo
    })

    const logoSize = computed(() => {
        switch(defaultConfig?.logos_FooterLogoSize) {
            case 'sm':
                return 'max-h-18 max-w-48'
            case 'lg':
                return 'max-h-54 max-w-144'
            case 'md':
            default:
                return 'max-h-36 max-w-96'
        }
    })

    const currentYear = computed(() => {
        return new Date().getFullYear()
    })
</script>

<style lang="scss" scoped>

</style>
