import * as Sentry from '@sentry/vue'
import {defineNuxtPlugin} from 'nuxt/app'
import {useRouter} from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter()
    const runtimeConfig = useRuntimeConfig()

    if (!runtimeConfig.public?.SENTRY_DSN) {
        return
    }

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: runtimeConfig.public.SENTRY_DSN,
        environment: runtimeConfig.public.SENTRY_ENVIRONMENT,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],

        // Configure this whole part as you need it!

        tracesSampleRate: 0.2, // Change in prod
        replaysSessionSampleRate: 1.0, // Change in prod
        replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
    })

    nuxtApp.hooks.hook('app:error', (error) => {
        Sentry.captureException(error)
    })

    nuxtApp.hooks.hook('vue:error', (error) => {
        Sentry.captureException(error)
    })
})
