import crypto from "node:crypto"

// This is a polyfill for uuid generator
export default defineNuxtPlugin(() => {
    if (typeof global === 'object') {
        if (typeof global?.crypto !== 'object') {
            global.crypto = crypto
        }
    }

    if (typeof window === 'object') {
        if (typeof window?.crypto !== 'object') {
            window.crypto = crypto
        }
    }
})