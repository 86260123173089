import { pageQuery } from '~/queries/page'
import { siteQuery } from '~/queries/site'
import pageEntryQueries from '~/queries/pages'

const sectionEntryTypes = {
    'ds_Events': ['ds_Events_Entry'],
    'ds_News': ['ds_News_Entry'],
    'ds_Pages': ['ds_StandardPage_Entry', 'ds_RedirectPage_Entry'],
    'ds_Homepage': ['ds_Homepage_Entry'],
}

export const useSiteStore = defineStore('siteStore', {
    state: () => ({
        entry: {},

        navigation: [],
        globalSets: [],

        siteDetails: {},
        digistormSettings: {},

        seomatic: {},
        variables: {},
        newsListingUrl: '',
        eventsListingUrl: '',

        themes: [],
        globalTheme: '',

        adminUrl: '',
        craftUrl: '',
        apiUrl: '',
        themeCss: [],
    }),
    getters: {
        locale() {
            const localeData = get(this.siteDetails, 'locale')

            // if the locale is en-US then just return en since that is the us default for dayjs
            if (localeData === 'en-US') {
                return 'en'
            }

            return localeData
        },

        // country code is just the 2 letter code for the country where locale includes language and country
        // au vs en-au, this is just the au part
        countryCode() {
            // transform from locale
            return toLower(last(split(get(this.siteDetails, 'locale'), '-')))
        }
    },
    actions: {

        getPageQuery() {
            let builtQuery = pageQuery
            let pageQueryHelper = ''

            // add possible sections to query
            const enabledSections = get(this.digistormSettings, 'enabledSections', [])

            each(enabledSections, (section) => {
                const sectionTypes = get(sectionEntryTypes, section)

                each(sectionTypes, (entryType) => {
                    builtQuery += get(pageEntryQueries, entryType, '')
                    pageQueryHelper += `
                        ... on ${entryType} {
                            ...${entryType}
                        }
                    `
                })
            })

            builtQuery = replace(builtQuery, '[entryQuery]', pageQueryHelper)

            return gql`${builtQuery}`
        },

        async loadPage(uri) {
            // this will only load the site once
            await callOnce(async () => {
                const { data: siteData, error: siteError } = await useAsyncQuery({
                    query: siteQuery,
                    key: 'siteData',
                    cache: true,
                })

                if (!isEmpty(siteError.value)) {
                    console.error('Error loading site data', siteError.value)
                    return
                }

                this.init(siteData.value)
            })

            // load colors for error pages
            this.initColorTheme()

            // no uri, so return
            if (isEmpty(uri)) {
                return
            }

            // load page data
            const { data: pageData, error: pageError } = await useAsyncQuery({
                query: this.getPageQuery(),
                variables: {
                    uri,
                },
                key: `layout-${uri}`,
                cache: true,
            })

            if (!isEmpty(pageError.value)) {
                throw createError({
                    statusMessage: 'Something went wrong',
                    message: "GraphQL error",
                    statusCode: 500,
                })
            }

            // replace page data entry
            this.entry = get(pageData.value, 'entry', {})

            if (isEmpty(this.entry?.slug)) {
                throw createError({
                    statusMessage: 'Page not found',
                    message: "The page you are looking for does not exist. <br /><br />Try finding what you are looking for by searching from the top menu or return back to the homepage",
                    statusCode: 404,
                })
            }

            this.initEntry()
        },

        // load settings for the site and various other settings
        init(data) {
            this.navigation = get(data, 'entries', [])
            this.globalSets = get(data, 'globalSets', [])

            this.themes = get(data, 'colorThemes', [])
            this.globalTheme = get(find(this.themes, { defaultTheme: true }), 'id')

            this.digistormSettings = JSON.parse(get(data, 'digistormComponents', '{}'))
            this.siteDetails = JSON.parse(get(data, 'siteDetails', '{}'))
        },

        initEntry() {
            this.seomatic = get(this.entry, 'seomatic', {})
            this.variables = JSON.parse(get(this.seomatic, 'metaSiteVarsContainer', '{}'))
        },

        initColorTheme() {
            // sort themes so default theme is first to fix css hierarchy issues
            const sortedThemes = sortBy(this.themes, (theme) => !theme.defaultTheme)

            each(sortedThemes, (theme) => {
                let colourCss = ''

                const themeData = JSON.parse(theme?.themeData ?? '{}')

                // add brand information
                const brandData = get(themeData, 'brand', {})
                const accentColor = get(themeData, 'accentColor', '')

                colourCss += `
[data-theme="${theme.id}"] {
`
                forIn(brandData, (value, key) => {
                    colourCss += `    --color-${key}: ${value};\n`
                })

                // add accent
                colourCss += `    --color-accent: ${themeData.accentColor};\n`

                colourCss += '}\n'

                const defaultColorValues = {
                    base: {
                        '--color-accent': `${accentColor}`,
                        '--text-subheading': 'var(--color-accent)',
                        '--text-heading': '#1f2937',
                        '--text-body': '#1f2937',
                        '--surface-background': '#ffffff',
                        '--surface-button-primary-1-default': 'var(--color-700)',
                        '--surface-button-primary-1-hover': 'var(--color-800)',
                        '--surface-button-primary-1-active': 'var(--color-900)',
                        '--text-button-primary-default': '#ffffff',
                    },
                    'color-subtle' : {
                        '--color-accent': `${accentColor}`,
                        '--text-subheading': 'var(--color-accent)',
                        '--text-heading': 'var(--color-700)',
                        '--text-body': '#1f2937',
                        '--surface-background': 'var(--color-100)',
                        '--surface-button-primary-1-default': 'var(--color-800)',
                        '--surface-button-primary-1-hover': 'var(--color-900)',
                        '--surface-button-primary-1-active': 'var(--color-950)',
                        '--text-button-primary-default': '#ffffff',
                    },
                    'color-invert': {
                        '--color-accent': `${accentColor}`,
                        '--text-subheading': 'var(--color-accent)',
                        '--text-heading': 'var(--color-50)',
                        '--text-body': 'var(--color-50)',
                        '--surface-background': 'var(--color-900)',
                        '--surface-button-primary-1-default': '#ffffff',
                        '--surface-button-primary-1-hover': '#ffffffcc',
                        '--surface-button-primary-1-active': '#ffffffb2',
                        '--text-button-primary-default': 'var(--color-900)',
                    },
                }

                // loop through all default values
                forIn(defaultColorValues, (cssVariables, key) => {
                    colourCss += `
    [data-theme="${theme.id}"][data-mode*="${key}"],
    [data-theme="${theme.id}"] [data-mode="${key}"] {`

                    forIn(cssVariables, (value, cssVar) => {
                        let newValue = value
                        const dataValue = get(themeData, `${key}.${cssVar}`)

                        // get value from theme data if it exists
                        if (!isEmpty(dataValue)) {
                            newValue = get(themeData, `${key}.${cssVar}`)
                        }

                        colourCss += `    ${cssVar}: ${newValue};\n`
                    })

                    colourCss += '}\n'
                })

                this.themeCss.push({
                    id: theme.id,
                    css: colourCss,
                })

            })
        }
    },
})
