import revive_payload_client_eDIIkV1evP from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_cojjzx5lpcmencu7brvnlizqqy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OEzQWnMx77 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_cojjzx5lpcmencu7brvnlizqqy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IHt1t6jbal from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_cojjzx5lpcmencu7brvnlizqqy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_2wV2y6umHX from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.25.0_vite@5.4.10_@types+node@22.9.0_sass@1.80_2hrjwkvbt4fgl3f4u2dad22byu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_N29K5HsNl4 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_cojjzx5lpcmencu7brvnlizqqy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_UgdyqgFwe0 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_cojjzx5lpcmencu7brvnlizqqy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_caY4fVMj0j from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_cojjzx5lpcmencu7brvnlizqqy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_B7C7uEpdYi from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_cojjzx5lpcmencu7brvnlizqqy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_q6WeiPRc3E from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/web-frontend/web-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_L7p2jccypJ from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0_cojjzx5lpcmencu7brvnlizqqy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_SWc138q2zG from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.25.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_yTDeeAPdD6 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.25.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_CzmdASJ22o from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.25.0_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_8Y2PAyt5fR from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_vue@3.5.12_typesc_isvqfmbsv6afddctqvf5zqhotu/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import apollo_craft_sclci9JHWi from "/home/runner/work/web-frontend/web-frontend/plugins/apollo-craft.ts";
import apollo_error_handler_a0IiUcX0mN from "/home/runner/work/web-frontend/web-frontend/plugins/apollo-error-handler.ts";
import crypto_IPXw5s4rs8 from "/home/runner/work/web-frontend/web-frontend/plugins/crypto.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/web-frontend/web-frontend/plugins/sentry.client.ts";
export default [
  revive_payload_client_eDIIkV1evP,
  unhead_OEzQWnMx77,
  router_IHt1t6jbal,
  _0_siteConfig_2wV2y6umHX,
  payload_client_N29K5HsNl4,
  navigation_repaint_client_UgdyqgFwe0,
  check_outdated_build_client_caY4fVMj0j,
  chunk_reload_client_B7C7uEpdYi,
  plugin_vue3_q6WeiPRc3E,
  components_plugin_KR1HBZs4kY,
  prefetch_client_L7p2jccypJ,
  switch_locale_path_ssr_SWc138q2zG,
  i18n_yTDeeAPdD6,
  plugin_CzmdASJ22o,
  plugin_8Y2PAyt5fR,
  apollo_craft_sclci9JHWi,
  apollo_error_handler_a0IiUcX0mN,
  crypto_IPXw5s4rs8,
  sentry_client_shVUlIjFLk
]